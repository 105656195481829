.grideoPlanesPricing{
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    row-gap: 24px;
}

.textoAppTextoMov{
    margin-top: 8px!important;
    font-weight: 800!important;
    font-size: 24px!important;
    line-height: 24px!important;
}

@media screen and (min-width: 600px) {
    



.idePlanesTexto{
    margin-top: 64px!important;
}
.marginPlanescont{
    margin-top: 80px!important;
}

.smPaperPricing{
    padding-bottom: 80px!important;
    padding-top: 72px!important;
    padding-left: 64px!important;
    padding-right: 64px!important;
}
.eseMPricingGrid{
    margin-top: 64px!important;
    grid-template-columns: repeat(2,minmax(0,1fr))!important;
}

.paddingFinalPricing{
    padding-top: 48px!important;
    padding-bottom: 48px!important;
    padding-left: 64px!important;
    padding-right: 64px!important;
}





.textoAppTextoMov{
    line-height: 40px!important;
    font-size: 32px!important;
}
.seccionAbajoPricing{
    padding-top: 72px!important;
    padding-bottom: 80px!important;
    padding-left: 64px!important;
    padding-right: 64px!important;
}

.abajoenPlanesTot{
    grid-template-columns: repeat(2,minmax(0,1fr))!important;
    margin-top: 64px!important;
}

}

@media screen and (min-width: 960px) {
    .planesAncho3{
        max-width: 1280px!important;
    }
    .grideoPlanesPricing{
        grid-template-columns: repeat(2,minmax(0,1fr))!important;
     column-gap: 24px!important;   
    }
    }

@media screen and (min-width: 1280px) {
.grideoPlanesPricing{
    grid-template-columns: repeat(3,minmax(0,1fr))!important;
    row-gap: 0!important;    
}

.eseMPricingGrid{
    gap: 64px !important;
    grid-template-columns: repeat(3,minmax(0,1fr))!important;

}

.abajoenPlanesTot{
    column-gap: 64px!important;
}
}




.svgPlanes{
    z-index: auto!important;
    position: absolute!important;
    bottom: 0!important;
    top: 0 !important;
    left: 0px !important;
    pointer-events: none!important;
}

.botoneYearPricing{
    height: 40px !important;
    align-items: center!important;
    padding-left: 16px!important;
    padding-right: 16px!important;
    cursor: pointer!important;
    border-radius: 9999px!important;
    font-weight: 500!important;
    border: none!important;
}

.shadowPrecios{
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
    --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;
}

.papelCartaPricing{
    flex-direction: column!important;
    max-width: 480px!important;
    padding: 24px !important;
    position: relative!important;
}

@media screen and (min-width: 960px) {
    .papelCartaPricing{
        max-width: none!important;
    }
}

@media screen and (min-width: 600px) {
.papelCartaPricing{
    padding-top: 48px!important;
    padding-bottom: 48px!important;
    padding-left: 40px!important;
    padding-right: 40px!important;
}
}

.ringo{
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000!important;
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000)!important;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)!important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)!important;
}

.texteoGreen600{
    
        --tw-text-opacity: 1!important;
        color: rgb(67 160 71/var(--tw-text-opacity))!important;
   
}
@media screen and (min-width: 960px) {
.tarjetaUnicaPricing{
    flex-direction: row!important;
    max-width: 960px!important;
}
}
@media screen and (min-width: 960px) {
    .paddingMarginUnica{
        padding: 40px !important;
    }

    .largeBoxUnicaPricing{
        padding-left: 40px!important;
        padding-right: 40px!important;
        padding-top: 48px!important;
        padding-bottom: 48px!important;
        min-width: 320px!important;
    }
    .botoneUnicaPricing{
        margin-top: auto!important;
    }

    .claseGrideoUnicaPricing{
        grid-template-columns: repeat(2,minmax(0,1fr))!important;
    }
    }
@media screen and (min-width: 600px) {
    .paddingMarginUnica{
        padding: 32px !important;
    }
    }

