.huevoSwiper .swiper-pagination {
  top: -18px !important;
}

.chipHomePricing {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: -16px !important;
  left: 0px !important;
  right: 0px !important;
}

.papelPricingHome {
  flex-direction: column !important;
  padding: 24px !important;
  position: relative !important;
  max-width: 480px !important;
}

@media (min-width: 960px) {
  .papelPricingHome {
    max-width: none !important;
  }
}

@media (min-width: 600px) {
  .papelPricingHome {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.ring2PricingHome {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}

@media (min-width: 600px) {
  .totalPricingHomeMt {
    margin-top: 0px !important;
  }
}

@media (min-width: 960px) {
  .totalPricingHomeMaxWidth {
    max-width: none !important;
  }
}

.motionCardPricingHome {
  display: grid !important;
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  row-gap: 24px !important;
}

@media (min-width: 1280px) {
  .motionCardPricingHome {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    -webkit-column-gap: 24px !important;
    column-gap: 24px !important;
  }
}

@media (min-width: 960px) {
  .motionCardPricingHome {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    column-gap: 24px !important;
  }
}

@media (min-width: 600px) {
  .completoPricingHomeFinal {
    padding-bottom: 48px !important;
    padding-top: 48px !important;
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
}

@media (min-width: 960px) {
  .citasHome {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

@media (min-width: 1200px) {
  .containerCitas {
    max-width: 1200px !important;
  }
}

@media (min-width: 600px) {
  .containerCitas {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media (min-width: 960px) {
  .gridoCitasHome {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.dotColor {
  color: transparent !important;
}

.hoverflechafoto44:hover {
  opacity: 1 !important;
  background-color: transparent !important;
}

.linkHomeRegistro {
  color: #004e56 !important;
  cursor: pointer !important;
}

.zIndexHome {
  z-index: 10 !important;
}

.pruebaNos6 {
  width: 222px;
  font-size: 56px;
  font-weight: 700;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  display: inline-flex !important;
  margin: 0 !important;
  color: #2e7d32 !important;
  z-index: 100 !important;
}

.pruebaNos5 {
  width: 160px;
  font-size: 56px;
  font-weight: 700;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  display: inline-flex !important;
  margin: 0 !important;
  color: #2e7d32 !important;
  z-index: 100 !important;
}

.homeMod1 {
  padding-left: 4vw !important;
  padding-right: 4vw !important;
  background-color: #f5f5f5 !important;
  position: relative !important;
}

.homeMod2 {
  display: block;
}

@media (min-width: 600px) {
  .homeMod2 {
    display: flex !important;
  }
}

.homeMod3 {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
}

@media (min-width: 900.01px) {
  .homeMod3 {
    margin-left: 50px !important;
    margin-right: 100px !important;
  }
}

@media (min-width: 600px) {
  .homeMod3 {
    margin-top: 80px !important;
    margin-bottom: 50px !important;
  }
}

.homeMod4 {
  font-size: 56px !important;
  font-weight: 700 !important;
  font-family: "Segoe UI", Arial, sans-serif !important;
  padding-right: 0px !important;
  line-height: 1 !important;
}

.homeMod5 {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Segoe UI", Arial, sans-serif !important;
  margin-top: 12px !important;
  line-height: 1.5 !important;
}

.homeMod6 {
  margin-top: 12px !important;
  display: block !important;
}

@media (min-width: 900.01px) {
  .homeMod6 {
    margin-top: 24px !important;
  }
}

@media (min-width: 600px) {
  .homeMod6 {
    display: flex !important;
  }
}

.homeMod7 {
  width: 100%;
  height: 39.682px !important;
}

@media (min-width: 600px) {
  .homeMod7 {
    width: auto !important;
    min-width: 180px !important;
  }
}

.homeMod8 {
  width: 100%;
  height: 39.682px !important;
  margin-top: 12px !important;
  margin-left: 0px !important;
}

@media (min-width: 600px) {
  .homeMod8 {
    width: auto !important;
    min-width: 130px !important;
    margin-left: 12px !important;
    margin-top: 0px !important;
  }
}

.homeMod9 {
  width: 100%;
  font-size: 12px !important;
  display: flex !important;
  align-items: end !important;
  margin-bottom: 20px !important;
  margin-right: 0px !important;
}

@media (min-width: 600px) {
  .homeMod9 {
    width: 40% !important;
    margin-bottom: 50px !important;
    margin-right: 50px !important;
  }
}

.homeMod10 {
  display: none !important;
  align-items: end !important;
  background-color: transparent !important;
  position: absolute !important;
  width: 180px !important;
  bottom: -40px !important;
  right: 4% !important;
}

@media (min-width: 1026.01px) {
  .homeMod10 {
    right: 8% !important;
  }
}

@media (min-width: 1200.01px) {
  .homeMod10 {
    right: 12% !important;
  }
}

@media (min-width: 1024.01px) {
  .homeMod10 {
    bottom: -80px !important;
  }
}

@media (min-width: 900.01px) {
  .homeMod10 {
    width: 228px !important;
  }
}
@media (min-width: 600.01px) {
  .homeMod10 {
    display: flex !important;
  }
}

.homeMod11 {
  object-fit: cover !important;
  min-height: 120 !important;
  display: block !important;
  width: 100% !important;
}
.homeMod12 {
  background-color: #f5f5f5 !important;
  display: flex !important;
}

.homeMod13 {
  padding-left: 4vw !important;
  padding-right: 4vw !important;
  display: block;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 24px !important;
  margin-bottom: 36px !important;
  position: relative !important;
}

@media (min-width: 600.01px) {
  .homeMod13 {
    display: flex !important;
    margin-top: 48px !important;
    margin-bottom: 80px !important;
  }
}

.homeMod14 {
  width: 100%;
  z-index: 2 !important;
}

@media (min-width: 600.01px) {
  .homeMod14 {
    width: 50% !important;
  }
}

.homeMod15 {
  height: 100% !important;
  width: 100% !important;
}

.homeMod16 {
  object-fit: cover !important;
}

.homeMod17 {
  margin-top: 24px !important;
  margin-bottom: 0px !important;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

@media (min-width: 600.01px) {
  .homeMod17 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    width: 50% !important;
  }
}

.homeMod18 {
  margin-left: 0px !important;
  font-family: "Segoe UI", Arial, sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  letter-spacing: -0.025em !important;
}

@media (min-width: 600.01px) {
  .homeMod18 {
    margin-left: 50px !important;
    font-size: 32px !important;
  }
}

.homeMod18B {
  margin-right: 0px !important;

  font-size: 24px !important;
}

@media (min-width: 600.01px) {
  .homeMod18B {
    margin-right: 50px !important;
    font-size: 32px !important;
  }
}

.homeMod19 {
  font-family: "Segoe UI", Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  margin-left: 0px !important;
  margin-top: 12px !important;
}

@media (min-width: 600.01px) {
  .homeMod19 {
    margin-top: 24px !important;
    margin-left: 50px !important;
  }
}

.homeMod20 {
  display: flex !important;
  margin-left: 0px !important;
  margin-top: 12px !important;
}

@media (min-width: 600.01px) {
  .homeMod20 {
    margin-top: 24px !important;
    margin-left: 50px !important;
  }
}

.homeMod21 {
  font-family: "Segoe UI", Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  width: fit-content !important;
  line-height: 1.5 !important;
}

.homeMod22 {
  font-size: 18px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding-left: 4px !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
}

.homeMod23 {
  max-height: 80 !important;
  height: 40 !important;
}

@media (min-width: 600.01px) {
  .homeMod23 {
    max-height: 120 !important;
    height: 80 !important;
  }
}

.homeMod24 {
  font-size: 24px !important;
  margin-top: 24px !important;
}

@media (min-width: 700.01px) {
  .homeMod24 {
    font-size: 32px !important;
    margin-top: 48px !important;
  }
}

.homeMod25 {
  padding-top: 60px !important;
  padding-bottom: 10px !important;
}

@media (min-width: 600.01px) {
  .homeMod25 {
    padding-top: 80px !important;
    padding-bottom: 20px !important;
  }
}

.homeMod26 {
  margin-right: 0px !important;
  margin-top: 12px !important;
}

@media (min-width: 600.01px) {
  .homeMod26 {
    margin-right: 50px !important;
    margin-top: 24px !important;
  }
}

.homeMod27 {
  width: 100%;
  z-index: 2 !important;
  margin-top: 24px !important;
}

@media (min-width: 600.01px) {
  .homeMod27 {
    width: 50% !important;
    margin-top: 0px !important;
  }
}

.homeMod28 {
  font-size: 28px !important;
}

@media (min-width: 600.01px) {
  .homeMod28 {
    font-size: 32px !important;
  }
}

.homeMod29 {
  font-size: 32px !important;
}

@media (min-width: 600.01px) {
  .homeMod29 {
    font-size: 40px !important;
  }
}

.homeMod30 {
  font-size: 32px !important;
}

@media (min-width: 700.01px) {
  .homeMod30 {
    font-size: 48px !important;
  }
}

.homeMod31 {
  font-size: 16px !important;
}

@media (min-width: 700.01px) {
  .homeMod31 {
    font-size: 24px !important;
  }
}

.homeMod32 {
  margin-top: 0px !important;
}

@media (min-width: 700.01px) {
  .homeMod32 {
    margin-top: 40px !important;
  }
}

.homeMod33 {
  margin-bottom: 24px !important;
}

@media (min-width: 600.01px) {
  .homeMod33 {
    margin-bottom: 48px !important;
  }
}

.homeMod34 {
  font-size: 24px !important;
}
@media (min-width: 700.01px) {
  .homeMod34 {
    font-size: 32px !important;
  }
}

.homeMod35 {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}
@media (min-width: 900.01px) {
  .homeMod35 {
    margin-left: 50px !important;
    margin-right: 50px !important;
    width: calc(100% - 100px) !important;
  }
}

.homeMod36 {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

@media (min-width: 600.01px) {
  .homeMod36 {
    margin-top: 48px !important;
  }
}

@media (min-width: 700.01px) {
  .homeMod36 {
    margin-bottom: 0px !important;
  }
}

.homeMod37 {
  display: none !important;
  padding-left: 4vw !important;
  padding-right: 4vw !important;
}

@media (min-width: 700.01px) {
  .homeMod37 {
    display: flex !important;
  }
}

@media (min-width: 1250.01px) {
  .homeMod37 {
    padding-left: 6vw !important;
    padding-right: 6vw !important;
  }
}

@media (min-width: 1200.01px) {
  .homeMod38 {
    min-height: 432.517px !important;
  }
}

.homeMod39 {
  display: flex !important;
}

@media (min-width: 700.01px) {
  .homeMod39 {
    display: none !important;
  }
}

.homeMod40 {
  padding-bottom: 24px !important;
}

@media (min-width: 600.01px) {
  .homeMod40 {
    padding-bottom: 48px !important;
  }
}

.homeMod41 {
  font-size: 24px !important;
  padding-top: 24px !important;
  margin-bottom: 24px !important;
}

@media (min-width: 700.01px) {
  .homeMod41 {
    font-size: 32px !important;
  }
}

@media (min-width: 600.01px) {
  .homeMod41 {
    padding-top: 48px !important;
    margin-bottom: 48px !important;
  }
}
.homeMod42 {
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    display: block !important;
}

@media (min-width: 900.01px) {
    .homeMod42 {
      display: flex !important;
    }
  }
  

@media (min-width: 600.01px) {
  .homeMod42 {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

.homeMod43 {
    width: 100%!important;
}

@media (min-width: 900.01px) {
    .homeMod43 {
      width: 70%!important;
    }
  }

  .homeMod44 {
    font-size: 18px!important;
}

@media (min-width: 600.01px) {
    .homeMod44 {
        font-size: 24px!important;
    }
  }

  .homeMod45 {
    width: 100%!important;
}

@media (min-width: 900.01px) {
    .homeMod45 {
      width: 30%!important;
    }
  }

  .homeMod46 {
    width: 100%!important;
    display: none!important;
}

@media (min-width: 900.01px) {
    .homeMod46 {
      width: 30%!important;
      display: flex!important;
    }
  }

  .homeMod47 {
    width: 100%!important;
    display: flex!important;
}

@media (min-width: 900.01px) {
    .homeMod47 {
      width: 30%!important;
      display: none!important;
    }
  }
